import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import BgEffect from "../components/BgEffect"
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"
import SEO from "../components/seo"
import logo from '../images/INMAGIK_COLOR.svg'
import get from 'lodash/get'







const WorkWithUsItem = ({datum}) => {

  return <div className="col-md-8 col-lg-8 offset-lg-2 offset-md-2 mb-5">
      <div className="d-flex flex-column card h-100">
        <p className="display-small font-200 p-2 bg-light text-center">
          <small className="text-dark"><span className="text-primary">{datum.type}</span> | {datum.title}</small>
          <h6 className="font-300 text-dark"><a href={`#${datum.code}`}>{datum.code}</a> | {datum.published}</h6>
          
        </p>
        
        
        <div className="flex-1 row no-gutters">
          <div className={`${datum.images && datum.images.length > 0 ? 'col-lg-12' : 'col-lg-12'} d-flex flex-column justify-content-between px-3`}>
            <p className="font-200 work-card-text" dangerouslySetInnerHTML={{ __html: datum.description }}></p>
          </div>

          {datum.images && datum.images.length > 0 && <div className="col-lg-12 text-center">
            <img className="img-cover" src={datum.images[0].publicURL}></img>
          </div>}
        </div>

        <div className="bg-light p-3">
            <h5><FormattedMessage id="what_we_offer">What we offer</FormattedMessage></h5>
            <p className="font-200 work-card-text" dangerouslySetInnerHTML={{ __html: datum.offer }}></p>
        </div>

        <div className="bg-white p-3">
            <h5><FormattedMessage id="what_we_ask">What we ask</FormattedMessage></h5>
            <p className="font-200 work-card-text" dangerouslySetInnerHTML={{ __html: datum.request }}></p>
        </div>

        <div className="bg-light p-3">
            <h5><FormattedMessage id="your_role">Your role</FormattedMessage></h5>
            <p className="font-200 work-card-text" dangerouslySetInnerHTML={{ __html: datum.role }}></p>
        </div>
      </div>
      
    </div>
  }


const WorkWithUsPage = ({data, intl}) => {
  const maybeData = data || {}
  const { careers } = { maybeData }
  const careersData = get(careers, 'edges', []).map(x => x.node)
    // .filter(node => node.lang === intl.locale)

  
  
  return (
  <Layout>
    <SEO title="Work with us - INMAGIK" />
    <div className="jumbo-title jumbo-small p-4 container-fluid text-center bg-black">
      <div className="display-3 font-200 text-white">
        <FormattedMessage id="work_with_us">Work with us</FormattedMessage>
      </div>
      {/* <h1 className="display-4">INMAGIK</h1> */}
      <div className="row my-4">
        <div className="col-md-6 offset-md-3">
          <p className="lead font-200 text-white">
            
            {careersData.length > 0 &&  <>
              <FormattedMessage id="work_with_us_positions">Work with us</FormattedMessage>{' '}
              <span className="text-primary"><a href="mailto:info@inmagik.com">info@inmagik.com</a></span>{` `}
              <FormattedMessage id="work_with_us_details">Work with us details</FormattedMessage>
            </>}
            {careersData.length === 0 &&  <FormattedMessage id="work_with_us_no_positions">Work with us</FormattedMessage>}
            
          </p>
        </div>
      </div>

    </div>
    <div className="main-content container py-5">
      
        {careersData.length > 0 && careersData.map(d => (
          <div id={`${d.code}`} key={d.id} className="pt-5">
          <div className="row" >
          <WorkWithUsItem  datum={d}></WorkWithUsItem>
          </div>
          <hr></hr>
          </div>
        ))}
      
    </div>
 
   
  </Layout>
)}

export default injectIntl(WorkWithUsPage)

// export const query = graphql`
//   query {
//     careers: allCareersYaml {
//       edges {
//         node {
//           id
//           title
//           code
//           published
//           type
//           description
//           offer
//           request
//           role
//           lang
//           images {
//             publicURL
//           }
//         }
//       }
//     }
     
//   }
// `
